import * as api from '../api';
import * as actionType from '../constants/actionTypes';

// Action creator

export const placeOrder = (formData,orderType,startDate,time,total,history) => async (dispatch) => {
    try {
        const cart = localStorage.getItem('cart');
        const items = JSON.parse(cart);
        dispatch({type:actionType.START_LOADING});
        let products = [];
        items.map((item)=>{ 
            products.push({
                product_id:item.product._id,
                quantity:item.qty,
                Price:item.product.price
            });
         });    
        const orderData = {
            customer:formData,
            products:products,
            type:orderType,
            date:startDate,
            time:time,
            total:total
        };
        console.log(orderData)
        const {data} = await api.placeOrderCall(orderData);
        dispatch({type:actionType.END_LOADING});
        history('/thank-you');
    } catch (error) {
        console.log(error.message);
    }
}

export const getOrders = () => async (dispatch) => {
    try {
        dispatch({type:actionType.START_ORDER_LOADING});
        const {data} = await api.myOrders();
        dispatch({type:actionType.FETCH_ALL_ORDER, payload:data});
        dispatch({type:actionType.END_ORDER_LOADING});
    } catch (error) {
        console.log(error.message);
    }
}
export const getOrder = (id) => async (dispatch) => {
    try {
        dispatch({type:actionType.START_ORDER_LOADING});
        const {data} = await api.myOrder(id);
        dispatch({type:actionType.FETCH_ORDER_BY_ID, payload:data});
        dispatch({type:actionType.END_ORDER_LOADING});
    } catch (error) {
        console.log(error.message);
    }  
}
