import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../constants/actionTypes';
import { Link } from 'react-router-dom';

const Box = (category) => {
  
    
    return(
        <div className='cat_single_block '>
        <div className='cat_img_block'>
            <Link  to={`/category/${category.categories._id}`}>
                <img src={category.categories.banner != ''? SERVER_URL+category.categories.banner : require('../../Images/best_col_img1.png')} 
                alt="" 
                className='w-full md:max-h-[217px] max-h-full'/>
            </Link>
        </div>
        <div className='cat_info bg-mybluecolor p-3 duration-300
        group-hover:bg-myorangeolor'>
            <Link className='flex justify-between' to={`/category/${category.categories._id}`}>
            <h3 className='text-xl font-primary text-white'>{category.categories.title}</h3>
            <span className='text-white'><FontAwesomeIcon icon={faArrowRight} /></span>
            </Link>
        </div>

    </div>
    );
}


export const CategoryBox = ({categories}) => {


    // const Box = categories.map((category)=>(  ))

  return (
    <>
    {categories.map((category)=>((category.showOnHomePage == 1) ? <Box key={category._id} categories={category} /> : ''))}
    {/* {categories.map((category)=>(<Box key={category._id} categories={category} />))} */}
    </>
  )
}
