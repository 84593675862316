import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {FormSchema} from './FormSchema';
import { signUp } from '../actions/auth';
import { clearError } from '../actions/general';
import { Error } from './Error';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
function Signup() {
    const dispatch = useDispatch();
    const history = useNavigate();

    const formik = useFormik({
        initialValues: {
          name: '',
          email: '',
          phone: '',
          business_name: '',
          business_address: '',
          password: '',
          confirmPassword: '',
        },
        onSubmit: values => {
            dispatch(clearError());
          dispatch(signUp(values,history));
            
        },
      });
      
      // console.log(error);
    
  return (
    <>
        <section className='form_section my-9'>
            <div className='container mx-auto'>
                <div className='login_row p-5 bg-mylightcolor'>
                    <h3 className='font-primary text-black text-xl font-semibold uppercase mb-5'>Sign up a New account  </h3>
                    <Error />
                    <form onSubmit={formik.handleSubmit}>
                    <div className='form_row flex md:flex-row flex-col md:gap-8 gap-3'>

                        <div className='basis-2/4'>
                            <label className='text-black font-primary text-base font-semibold mb-3 block'>Full Name</label>
                            <input
                                    type="text"
                                    placeholder="Enter your full name"
                                    name="name"
                                    className="p-4 placeholder-black text-black relative bg-white text-sm 
                                    border-b-2 border-solid border-white w-full mb-4 font-normal"
                                    value={formik.values.name}
                                    onBlur = {formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                { formik.errors.name && formik.touched.name ? 
                                    (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.name}</span>)
                                    : null
                                }
                        </div>

                        <div className='basis-2/4'>
                            <label className='text-black font-primary text-base font-semibold mb-3 block'>Phone</label>
                            <input
                                    type="telephone"
                                    placeholder="Enter your phone number"
                                    name="phone"
                                    className="p-4 placeholder-black text-black relative bg-white text-sm 
                                    border-b-2 border-solid border-white w-full mb-6 font-normal"
                                    value={formik.values.phone}
                                    onBlur = {formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                { formik.errors.phone && formik.touched.phone ? 
                                    (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.phone}</span>)
                                    : null
                                }
                        </div>

                    </div>

                    <div className='form_row flex md:flex-row flex-col md:gap-8 gap-3'>

                        <div className='basis-2/4'>
                            <label className='text-black font-primary text-base font-semibold mb-3 block'>Email Address</label>
                            <input
                                    type="email"
                                    placeholder="Enter Your Email Address"
                                    name="email"
                                    className="p-4 placeholder-black text-black relative bg-white text-sm 
                                    border-b-2 border-solid border-white w-full mb-6 font-normal"
                                    value={formik.values.email}
                                    onBlur = {formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                { formik.errors.email && formik.touched.email ? 
                                    (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.email}</span>)
                                    : null
                                }

                        </div>

                        <div className='basis-2/4'>
                            <label className='text-black font-primary text-base font-semibold mb-3 block'>Business Name</label>
                            <input
                                    type="text"
                                    placeholder="Enter your business name"
                                    name="business_name"
                                    className="p-4 placeholder-black text-black relative bg-white text-sm 
                                    border-b-2 border-solid border-white w-full mb-6 font-normal"
                                    value={formik.values.business_name}
                                    onBlur = {formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                { formik.errors.business_name && formik.touched.business_name ? 
                                    (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.business_name}</span>)
                                    : null
                                }
                        </div>

                    </div>

                    <div className='form_row flex md:flex-row flex-col md:gap-8 gap-3'>

                        <div className='basis-2/4'>
                            <label className='text-black font-primary text-base font-semibold mb-3 block'>Password</label>
                            <input
                                    type="password"
                                    placeholder="......."
                                    name="password"
                                    className="p-4 placeholder-black text-black relative bg-white text-sm 
                                    border-b-2 border-solid border-white w-full mb-6 font-normal"
                                    value={formik.values.password}
                                    onBlur = {formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                { formik.errors.password && formik.touched.password ? 
                                    (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.password}</span>)
                                    : null
                                }
                        </div>

                        <div className='basis-2/4'>
                            <label className='text-black font-primary text-base font-semibold mb-3 block'>confirm Password</label>
                            <input
                                    type="password"
                                    placeholder="......."
                                    name="confirmPassword"
                                    className="p-4 placeholder-black text-black relative bg-white text-sm 
                                    border-b-2 border-solid border-white w-full mb-6 font-normal"
                                    value={formik.values.confirmPassword}
                                    onBlur = {formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                { formik.errors.confirmPassword && formik.touched.confirmPassword ? 
                                    (<span className='my_vaid_error text-left text-red-600 block mb-2'>{formik.errors.confirmPassword}</span>)
                                    : null
                                }                          
                        </div>

                    </div>
                    <div className='form_row flex md:flex-row flex-col md:gap-8 gap-3'>
                        <div className='basis-2/4'>
                        <label className='text-black font-primary text-base font-semibold mb-3 block'>Business Address</label>
                            <input
                                    type="text"
                                    placeholder="Enter your business address"
                                    name="business_address"
                                    className="p-4 placeholder-black text-black relative bg-white text-sm 
                                    border-b-2 border-solid border-white w-full mb-6 font-normal"
                                    value={formik.values.business_address}
                                    onBlur = {formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                { formik.errors.business_address && formik.touched.business_address ? 
                                    (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.business_address}</span>)
                                    : null
                                }
                        </div>  
                        <div className='basis-2/4'>
                             <button type='submit' className=' bg-mybluecolor text-sm mt-10 font-primary text-white px-8 py-3 border-2 border-mybluecolor
                                    duration-300 hover:bg-myorangeolor hover:border-myorangeolor'>Sign up</button>
                         </div>                  
                    </div>                                    

                    

                  
                    </form> 
                </div>
            </div>
        </section>
    </>
  )
}

export default Signup