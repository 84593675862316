import './App.css';
import React, { useEffect, useState } from 'react';
import { Routes,Route,Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getBanners } from './actions/banners';
import { getCategories } from './actions/categories';
import { getTopProducts } from './actions/products';
import { getSlides } from './actions/slides';
import ScrollTop from './components/ScrollTop';
import Home from './components/Home';
import About from './components/About';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Signup from './components/Signup';
import Category from './components/Category';
import ProductList from './components/ProductList';
import Product from './components/Product';
import { ThankyouFroContactUs } from './components/ThankyouFroContactUs';
import {Cart} from './components/cart/CartPage'
import Checkout from './components/checkout/Checkout';
import MyOrder from './components/MyOrder';
import MyOrders from './components/MyOrders';
import {Thankyou} from './components/Thankyou';
import ContactUs from './components/ContactUs';
import jwt_decode from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';
import Popup from './components/Popup';

function App() {
  // const user = localStorage.getItem('profile');
  const [cartCount,setCartCount] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const [user,setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [cartMsg,SetCartMsg] = useState(false);  
  const [navbar, setNavbar] = useState(false);
  const history = useNavigate();

  const logout = () => {
  dispatch({type:'LOGOUT'});
  setUser(null);
  history('/');
  }

  useEffect(()=>{
    dispatch(getBanners());
    dispatch(getCategories());
    dispatch(getTopProducts());
    dispatch(getSlides());
  },[]);

  useEffect(()=>{
    // prime web developer
    const token = user?.token;
    if(token){
        const decodedToken = jwt_decode(token);
        if(decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem('profile')));

},[location]);



  return (
    <>
    <Header cartCount={cartCount} user={user} logout={logout} navbar={navbar} setNavbar={setNavbar} />
   
    <ScrollTop setNavbar={setNavbar} />
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/home' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/login' element={(!user?<Login/>:<Navigate to='/home' />) }/>
        <Route path='/signup' element={ (!user? <Signup/> : <Navigate to='/home' />) }/>
        <Route path='/category' element={<Category setCartCount={setCartCount} cartCount={cartCount} SetCartMsg={SetCartMsg} />}/>
        <Route path='/category/:id' element={<Category setCartCount={setCartCount} cartCount={cartCount} SetCartMsg={SetCartMsg} />}/>
        <Route path='/product/search' element={<ProductList setCartCount={setCartCount} cartCount={cartCount} SetCartMsg={SetCartMsg} />}/>
        <Route path='/product/:id' element={<Product setCartCount={setCartCount} cartCount={cartCount} SetCartMsg={SetCartMsg} />}/>
        <Route path='/cart' element={<Cart cartCount={cartCount} setCartCount={setCartCount}/>}/>
        <Route path='/checkout' element={(user?<Checkout />:<Navigate to='/login' />)}/>
        <Route path='/my-orders' element={(user?<MyOrders user={user} logout={logout} />:<Navigate to='/login' />)}/>
        <Route path='/my-order/:id' element={(user?<MyOrder user={user} logout={logout} />:<Navigate to='/login' />)}/>
        <Route path='/thank-you' element={<Thankyou/>}/>
        <Route path='/thankyou' element={<ThankyouFroContactUs/>}/>
        <Route path='/contact-us' element={<ContactUs/>}/>
      </Routes>
    <Footer/>
    </>
  );
}

export default App;
