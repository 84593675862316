import React, { useState,useEffect } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCaretDown,faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { useDispatch,useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { getProductsByCategory } from '../actions/products';
import { getCategory } from '../actions/categories';
import ProductSection from './productPage/ProductSection.js';
import { Link } from 'react-router-dom';
import Loading from './Loading';

function Category({cartCount,setCartCount,SetCartMsg}) {

    const {id} = useParams();
    const dispatch = useDispatch();
 

    const [catList,setCatList] = useState(false);
    // const [columnset,setColumnSet] = useState('md:basis-[19%] basis-[45%]');
    const { categories,category,isCatLoading } = useSelector(( state )=> state.categories);
    
    useEffect(() => {
        dispatch(getCategory(id)); 
    },[id]);
    
    
    if(isCatLoading){ return <Loading />; }
    // if(!isLoading && !categories.length){ return 'No Category'; }
 
    



   const nav = categories.map((category)=>(  
   <li key={category._id}>
    <Link to={`/category/${category._id}`} className='p-4 font-primary bg-mybluecolor text-white text-sm block duration-300
    border-b-2 border-white hover:bg-myorangeolor'>{category.title}</Link> 
    </li> ));


  return (
    <>
        <section className='cat_page my-9'>
            <div className='container mx-auto'>
                <div className='cat_inner_content flex md:flex-row flex-col gap-5'>
                    <div className='cat_tabs md:basis-1/5'>
                        <h3 className='text-white font-primary p-4 bg-myorangeolor block relative'>
                            <button className='block w-full text-left' onClick={() => setCatList(!catList)}>
                                Shop by Categories
                                <div className='icons_div md:hidden block'>
                                    {
                                        catList ?
                                        <FontAwesomeIcon icon={faCaretDown} className="absolute right-3 top-5"/>
                                        :
                                        <FontAwesomeIcon icon={faCaretUp} className="absolute right-3 top-5"/>
                                    }
                                </div>
                            </button>
                        </h3>                    
                        <ul className={`bg-mybluecolor ${catList  ? 'block' :'md:block hidden' }`}>
                            {nav}                            
                        </ul>
                    </div>
                   <ProductSection setCartCount={setCartCount} cartCount={cartCount} SetCartMsg={SetCartMsg} category={category} />
                </div>
            </div>
        </section>
    </>
  )
}

export default Category