import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'


function AddToCartPopup({OpenPopup,setOpenPopup}) {


const [isOpen,setIsOpen] = useState('hidden');


useEffect(()=>{

    if(OpenPopup != false){
        setIsOpen('');
    }
},[OpenPopup]);

useEffect(() => {
    setTimeout(() => {
        setIsOpen('hidden');
    }, 1000);
    
  }, [OpenPopup]);

const HandleClick = (value) =>{

    setIsOpen('hidden');
    setOpenPopup(false);
}

  return (
    <>
      
<div class={`cartPopup fixed z-10 overflow-y-auto top-0 w-full left-0 ${isOpen}`} id="modal">
  <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0"> 

    <div class="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[25rem] sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="relative bg-[#0b348c] rounded-lg shadow dark:bg-gray-700">
                    <div class="p-6 space-y-3 flex flex-col justify-center justify-items-center">
                        <div className='m-auto'>
                            <FontAwesomeIcon icon={faCheckCircle} className="text-green-700 text-[50px] mx-2"/>
                        </div>
                        <div className='w-full'>                        
                            <p class=" uppercase text-[14px] py-0 leading-relaxed font-bold text-white w-full text-center mb-5">
                                    {OpenPopup}
                            </p>                        
                                                    
                        </div>                       
                    </div>        
                    {/* <div class="flex items-center justify-center p-6 p- space-x-2 rounded-b dark:border-gray-600"> */}
                        {/* <Link to={'/cart'} className='text-white bg-red-400 hover:bg-red-600 font-medium rounded-lg text-[20px] px-10 py-5 text-center popupBtn'>View Cart</Link> */}
                        {/* <button type="button" onClick={() => HandleClick('Yes')} class="text-white bg-red-400 hover:bg-red-600 font-medium rounded-lg text-[20px] px-10 py-5 text-center popupBtn">View Cart</button> */}
                        {/* <button type="button" onClick={() => HandleClick('No')} class="text-white bg-green-600 hover:bg-green-700  rounded-lg text-[20px] px-10 py-5 popupBtn">Continue Shopping</button> */}
                        {/* <button type="button" onClick={() => HandleClick('No')} class="text-white bg-green-600 hover:bg-green-700  rounded-lg text-[14px] px-2 py-2 popupBtn">Close</button> */}
                    {/* </div> */}
                </div>
    </div>
  </div>
</div>
    </>
  );
}

export default AddToCartPopup;