import React, { useState } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faList,faTableCells } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import { useParams,useLocation } from "react-router-dom";
// import { getProductsByCategory } from '../../actions/products';
import ProductWidget from './ProductWidget';
import CustomPagination from './Pagination';
import AddToCartPopup from '../AddToCartPopup';

function useQuery(){
    return new URLSearchParams(useLocation().search);
}
function ProductSection({category,cartCount,setCartCount,SetCartMsg}) {
    const {id} = useParams();
    const query = useQuery();
    const page = query.get('page') || 1;
    const [OpenPopup,setOpenPopup] = useState(false);

    // const [catList,setCatList] = useState(false);
    const [columnset,setColumnSet] = useState('md:basis-[19%] basis-[45%]');


    const { products } = useSelector(( state )=> state.products);

    
    // if(isLoading){ return 'Loading'; }
    // if(!isLoading && !products.length){ return 'No Product Found'; }


    return (
        <>
        <AddToCartPopup OpenPopup={OpenPopup} setOpenPopup={setOpenPopup} />
            <div className='cat_product_list basis-4/5'>                                 
                <div className='top_cat_titles flex md:flex-row flex-col pb-4 border-b-2 border-[#898989] mb-4 md:mt-5 mt-0'>
                    <div className='cat_top_title basis-2/4'>
                        <h4 className='font-primary text-mydarkcolor text-xl font-semibold md:text-left text-center md:mb-0 mb-3'>{category.title}</h4>
                    </div>
                    <div className='cat_totals basis-2/4 md:text-right text-center'>
                        <p className='text-mydarkcolor font-primary md:text-base text-sm md:text-right text-center inline-block'>{products.length} Products</p>
                        <ul className='toggles_list inline-block'>
                            <li className='inline-block'>
                                <button onClick={() => setColumnSet('md:basis-[19%] basis-[45%]')}>
                                    <FontAwesomeIcon icon={faTableCells} className="text-mydarkcolor mx-2"/>
                                </button>
                            </li>
                            <li className='inline-block'>
                                <button onClick={() => setColumnSet('flex flex-row md:basis-full basis-full my_full_new gap-5')}>
                                    <FontAwesomeIcon icon={faList} className="text-mydarkcolor "/>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='cat_avaliable_list'>
                    <ul className='flex flex-wrap gap-2 justify-center'>
                        {products.map((product)=> (<ProductWidget setOpenPopup={setOpenPopup} columnset={columnset} product={product} key={product._id} setCartCount={setCartCount} cartCount={cartCount} SetCartMsg={SetCartMsg} />))}       
                    </ul>
                    <CustomPagination category={id} page={page} />                   
                </div>
            </div>
        </>
            );

}

export default ProductSection