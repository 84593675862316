import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { clearError } from '../../actions/general';
import { Error } from '../Error';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CartProduct } from '../cart/CartProduct';
import { placeOrder } from '../../actions/order';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CheckoutSchema } from '../Validation/CheckoutSchema';

export default function Checkout() {
    const user = JSON.parse(localStorage.getItem('profile'));
    const navigate = useNavigate();
    const cart = localStorage.getItem('cart');
    const items = JSON.parse(cart);
    const [orderType,setOrderType] = useState('');
    const [orderTypeError,setOrderTypeError] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [dayType, setDayType] = useState('weekdays');
    const [time, setTime] = useState('9:00 AM');

    useEffect(()=>{
        let day = startDate.getDay();
        if(day == 0 || day == 6){
            setDayType('weekend');        
        }else{
            setDayType('weekdays');           
        }
    },[startDate]);

    let total = 0;
    items.map((item) => { 
      let price = item.product.price * item.qty;
      total += price;
    });
    const dispatch = useDispatch();
    const history = useNavigate();
    const data ={
        name: user?.result?.name,
        email: user?.result?.email,
        phone: user?.result?.phone,
        business_name: user?.result?.business_name,
        business_address: user?.result?.business_address,        
        time: user?.result?.time,
        additional_comments:'',
      }
    const formik = useFormik({
        initialValues: data,
        enableReinitialize: true,
        validationSchema:CheckoutSchema,        
        onSubmit: values => {
            if(orderType !== ''){
                dispatch(clearError());
                dispatch(placeOrder(values,orderType,startDate,time,total,history));
            }else{
                alert('Order Type is required');
                setOrderTypeError('Order Type is required');
            }
          
          
        },
      });
      if(!cart){ return navigate('/'); } 
      const handleDelete = () => { }
      const handleOrderType = (value)=>{
        setOrderType(value);        
      }
      const handleTime = (value)=>{
        setTime(value);        
      }

  return (
    <>
        <section className='form_section my-9'>
            <div className='container mx-auto'>
                <div className='login_row p-5 bg-mylightcolor'>
                    <h3 className='font-primary text-black text-xl font-semibold uppercase mb-5'>Place your order  </h3>
                    <Error />
                    <form onSubmit={formik.handleSubmit}>
                    <div className='form_row flex md:flex-row flex-col md:gap-8 gap-3'>
                        <div className='basis-2/5'>
                            <div className='basis-full'>
                                <label className='text-black font-primary text-base font-semibold mb-3 block'>Full Name</label>
                                <input                                    
                                        type="text"
                                        placeholder="Enter your full name"
                                        name="name"
                                        className="p-4 placeholder-black text-black relative bg-white text-sm 
                                        border-b-2 border-solid border-white w-full mb-4 font-normal"
                                        value={formik.values.name}
                                        onBlur = {formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                    { formik.errors.name && formik.touched.name ? 
                                        (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.name}</span>)
                                        : null
                                    }
                            </div>
                            <div className='basis-full'>
                                <label className='text-black font-primary text-base font-semibold mb-3 block'>Phone</label>
                                <input
                                        type="telephone"
                                        placeholder="Enter your phone number"
                                        name="phone"
                                        className="p-4 placeholder-black text-black relative bg-white text-sm 
                                        border-b-2 border-solid border-white w-full mb-6 font-normal"
                                        value={formik.values.phone}
                                        onBlur = {formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                    { formik.errors.phone && formik.touched.phone ? 
                                        (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.phone}</span>)
                                        : null
                                    }
                            </div>
                            <div className='basis-full'>
                                <label className='text-black font-primary text-base font-semibold mb-3 block'>Email Address</label>
                                <input
                                        type="email"
                                        placeholder="Enter Your Email Address"
                                        name="email"
                                        className="p-4 placeholder-black text-black relative bg-white text-sm 
                                        border-b-2 border-solid border-white w-full mb-6 font-normal"
                                        value={formik.values.email}
                                        onBlur = {formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                    { formik.errors.email && formik.touched.email ? 
                                        (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.email}</span>)
                                        : null
                                    }

                            </div>                        
                            <div className='basis-full'>
                                <label className='text-black font-primary text-base font-semibold mb-3 block'>Business Name</label>
                                <input
                                        type="text"
                                        placeholder="Enter your business name"
                                        name="business_name"
                                        className="p-4 placeholder-black text-black relative bg-white text-sm 
                                        border-b-2 border-solid border-white w-full mb-6 font-normal"
                                        value={formik.values.business_name}
                                        onBlur = {formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                    { formik.errors.business_name && formik.touched.business_name ? 
                                        (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.business_name}</span>)
                                        : null
                                    }
                            </div>                        
                            <div className='basis-full'>
                            <label className='text-black font-primary text-base font-semibold mb-3 block'>Business Address</label>
                                <input
                                        type="text"
                                        placeholder="Enter your business address"
                                        name="business_address"
                                        className="p-4 placeholder-black text-black relative bg-white text-sm 
                                        border-b-2 border-solid border-white w-full mb-6 font-normal"
                                        value={formik.values.business_address}
                                        onBlur = {formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                    { formik.errors.business_address && formik.touched.business_address ? 
                                        (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.business_address}</span>)
                                        : null
                                    }
                            </div>
                            <div className='basis-full'>
                            <label className='text-black font-primary text-base font-semibold mb-3 block'>Additional Comments</label>
                                {/* <input
                                        type="text"
                                        placeholder="Additional Comments"
                                        name="additional_comments"
                                        className="p-4 placeholder-black text-black relative bg-white text-sm 
                                        border-b-2 border-solid border-white w-full mb-6 font-normal"
                                        value={formik.values.additional_comments}
                                        onBlur = {formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                    { formik.errors.additional_comments && formik.touched.additional_comments ? 
                                        (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.additional_comments}</span>)
                                        : null
                                    } */}
                                    <textarea placeholder="Additional Comments"
                                        name="additional_comments"
                                        className="p-4 placeholder-black text-black relative bg-white text-sm 
                                        border-b-2 border-solid border-white w-full mb-6 font-normal resize-none"
                                        value={formik.values.additional_comments}
                                        onBlur = {formik.handleBlur}
                                        onChange={formik.handleChange}>
                                        
                                    </textarea>
                                    { formik.errors.additional_comments && formik.touched.additional_comments ? 
                                        (<span className='my_vaid_error text-red-600 block mb-2'>{formik.errors.additional_comments}</span>)
                                        : null
                                    }
                            </div>

                            <div className='basis-full'>
                                <div className='mb-2'>
                                <input
                                    name="delivery_type"
                                    value="Cash-on-delivery"
                                    type="radio"
                                    onChange={(e) => handleOrderType(e.target.value)}
                                    defaultChecked={orderType === 'Cash-on-delivery'}
                                    id="cash"
                                />
                                    <label  for='cash' className='text-black font-primary text-base font-semibold ml-2'>Delivery</label>
                                </div>

                                <div>
                                <input
                                    name="delivery_type"
                                    value="Pick-up"
                                    type="radio"
                                    onChange={(e) => handleOrderType(e.target.value)}
                                    defaultChecked={orderType === 'Pick-up'}
                                    id="pickup"
                                />
                                    <label for="pickup" className='text-black font-primary text-base font-semibold ml-2'>Pick up</label>
                                </div>
                                <div className="my_vaid_error text-red-600 block mb-2">
                                    {orderTypeError}
                                </div> 
                            </div> 
                            {(orderType == 'Pick-up')?(
                            <div className='form_row flex md:flex-row flex-col md:gap-8 gap-3'>                          
                                <div className='basis-2/5'>
                                    <div className='mb-2'>
                                        {/* <input type='date' className="p-4 placeholder-black text-black relative bg-white text-sm 
                                            border-b-2 border-solid border-white w-full mb-6 font-normal" /> */}
                                        <label className='text-black font-primary text-base font-semibold ml-2'>Date</label>
                                        <DatePicker 
                                        selected={startDate} 
                                        onChange={(date) => setStartDate(date)}                                 
                                        className="p-4 placeholder-black text-black relative bg-white text-sm 
                                            border-b-2 border-solid border-white w-full mb-6 font-normal" />


                                    </div>
                                </div>
                                <div className='basis-2/5'>
                                    <div className='mb-2'>
                                        <label className='text-black font-primary text-base font-semibold ml-2'>Time</label>
                                    {(dayType == 'weekdays')?(
                                        <select name='time' defaultValue={time} onChange={(e) => handleTime(e.target.value)} className='p-4 placeholder-black text-black relative bg-white text-sm 
                                            border-b-2 border-solid border-white w-full mb-6 font-normal'>
                                            <option value="9:00 AM">9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM">5:00 PM</option>
                                            <option value="6:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>                                       
                                        </select>
                                        ):(
                                        <select name='time' defaultValue={time} onChange={(e) => handleTime(e.target.value)} className='p-4 placeholder-black text-black relative bg-white text-sm 
                                            border-b-2 border-solid border-white w-full mb-6 font-normal'>                        
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM">5:00 PM</option>
                                            <option value="6:00 PM">6:00 PM</option>                                      
                                        </select>
                                        )}
                                    </div>
                                </div>
                            </div>
                            ):''}
                        </div>
                        <div className='basis-3/5'>                            
                        { items.map((item)=>{ return(<CartProduct handleDelete={handleDelete} page='checkout' key={item._id} item={item} />) }) }
                            <div className='total'>
                                <ul className='flex md:flex-row flex-col md:justify-end justify-center items-center'>
                                    <li>
                                        <h4 className='text-dark text-2xl font-primary font-semibold p-2'>Total: <span>$ {total.toFixed(2)}</span></h4>
                                    </li>                        
                                    <li>    
                                        <button type='submit' className='bg-mybluecolor text-sm mt-2 font-primary text-white px-8 py-3 border-2 border-mybluecolor
                                                        duration-300 hover:bg-myorangeolor hover:border-myorangeolor'>Submit Order</button>
                                    </li>
                                </ul>
                            </div>
                            <div className='note_box mt-4 border-2 border-mybluecolor p-3 rounded-md bg-white'>
                                <h3 className='font-primary text-base font-semibold uppercase mb-3'>Disclaimer</h3>
                                    <p className='font-primary font-normal text-black'>Prices are subject to change without notice! Not responsible for any 
                                    typographical errors! No rain check and restrictions apply!</p>
                            </div>
                        </div>
                    </div> 
                    </form> 
                </div>
            </div>
        </section>
    </>
  )
}
