import axios from 'axios';

const API = axios.create({baseURL:'https://admin.shopwex.com'});
// const API = axios.create({baseURL:'http://localhost:5000'});

API.interceptors.request.use((req)=>{
    if(localStorage.getItem('profile')){
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
});

export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchPost = (id) => API.get(`/posts/${id}`);
export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const createPost = (newPost) => API.post('/posts',newPost);
export const updatePost = (id,updatedPost) => API.patch(`/posts/${id}`,updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const comment = (value,id) => API.post(`/posts/${id}/commentPost`,{value});

export const signIn = (formData) => API.post('/users/signin',formData);
export const signUp = (formData) => API.post('/users/signup',formData);


export const fetchBanners = () => API.get(`/api/home-page-sections`);
export const fetchSlides = () => API.get(`/api/home-page-slides`);
export const fetchCategories = () => API.get(`/api/categories`);
export const fetchCategory = (id) => API.get(`/api/category/${id}`);

export const fetchProductsByCategories = (ids) => API.post(`/api/products-by-categories`,{categoryIds:ids});
export const fetchProducts = () => API.get(`/api/products`);
export const fetchProduct = (id) => API.get(`/api/product/${id}`);
export const fetchProductBySearch = (searchQuery) => API.get(`/api/search?searchQuery=${searchQuery}`);
export const getTopProducts = () => API.get(`/api/top-product`);

export const placeOrderCall = (orderData) => API.post(`/api/place-order`,orderData);
export const myOrders = () => API.get(`/api/my-orders`);
export const myOrder = (id) => API.get(`/api/order/${id}`);

export const contactUs = (contactDetails) => API.post(`/api/contact-us`,contactDetails);