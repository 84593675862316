import React, { useEffect, useState } from 'react'
import { CartProduct } from './CartProduct'
import { Link, useNavigate } from 'react-router-dom'

export const Cart = ({cartCount,setCartCount}) => {
  const navigate = useNavigate();
  const cart = localStorage.getItem('cart');
  const items = JSON.parse(cart);
  const [products,setProducts] = useState([]);
  
  useEffect(()=>{
    setProducts(items);
  },[])
  if(!cart){ return navigate('/'); }

  let total = 0;
  items.map((item) => { 
    let price = item.product.price * item.qty;
    total += price;
  }) 

const handleDelete = (id) => {
  // posts.filter((post)=> post._id !== action.payload
  let newCart = items.filter((item)=>item._id !== id);
   localStorage.setItem('cart',JSON.stringify(newCart));
   setProducts(newCart);
   setCartCount(cartCount-1);

}
const updateItem = (id,qty) => {
  
  let newCart = items.map((item)=>{
    if(item._id == id){
      return {
        product:item.product,
        qty:qty,
        _id:id
      };
    }else{
      return item;
    }
  });

  localStorage.setItem('cart',JSON.stringify(newCart));
  setProducts(newCart);
}



  return (
    <section className='cart my-6'>

      <div className='container mx-auto'>

        <div className='cart_items_box md:mx-36 mx-10'>
          <div className='cart_titles  md:flex hidden md:flex-row flex-col gap-5'>
            <div className='basis-1/12'>
              <h4 className='text-dark text-large font-primary font-semibold p-2'>S:No</h4>
            </div>
            <div className='basis-9/12'>
              <h4 className='text-dark text-large font-primary font-semibold p-2'>Product Details</h4>
            </div>
            <div className='basis-1/12'>
              <h4 className='text-dark text-large font-primary font-semibold p-2'>Price</h4>
            </div>
            <div className='basis-1/12'>
              {/* <h4 className='text-dark text-large font-primary font-semibold p-2'>Price</h4> */}
            </div>
          </div>
          { products.map((item,i)=>{  return(<CartProduct updateItem={updateItem} handleDelete={handleDelete} count={i+1} page='cart' key={item._id} item={item} />);
           }) }
          
          <div className='total'>
            <ul className='flex md:flex-row flex-col md:justify-end justify-center items-center'>
              <li>
                <h4 className='text-dark text-2xl sm:text-xl md:text-xl lg:text-2xl font-primary font-semibold p-2'>Total: <span>$ {total.toFixed(2)}</span></h4>
              </li>
            
              <li>

              <Link to='/checkout' className=' bg-mybluecolor text-sm mt-2 font-primary text-white px-8 py-3 border-2 border-mybluecolor
                                      duration-300 hover:bg-myorangeolor hover:border-myorangeolor'>Proceed to Checkout</Link>
              </li>
            </ul>
          </div>
        </div>




      </div>
    </section>
  )
}
