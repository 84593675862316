import * as api from '../api';
import * as actionType from '../constants/actionTypes';

// Action creator

export const clearError = () => async (dispatch) => {
    dispatch({type:actionType.AUTH_ERROR_REMOVE});
}

export const contactUs = (formData,history) => async (dispatch) => {
    const {data} = await api.contactUs(formData);
    history('/thankyou');
}
