import React from 'react'
import customdrink from '../Images/drink_bg_img.png';
import customcig from '../Images/cigrate_section_bg.png';
import { SERVER_URL } from '../constants/actionTypes';
import { CategoryBox } from './Home/CategoryBox';

function TopSelling({drinksBanner,cigarettesBanner,categories}) {
    
  return (
    <section className='top_selling_section'>
        <div className='container mx-auto'>
            
            <div className='section_title text-center mb-2'>
                <h className="font-primary text-3xl text-black">Browse Top Selling Categories</h>
            </div>

            <div className='cats_col_block mb-5'>
            { <CategoryBox categories={categories} /> }
              
            </div>   

        </div>
    </section>
  )
}

export default TopSelling